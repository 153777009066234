import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vrijetijdsactiviteiten-op-het-kasteel"
    }}>{`Vrijetijdsactiviteiten op het kasteel`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ACY/Eyc7ES5BFSc3EiQyETREFjtQGyI1FCU4FCc7FB8xHDBHGzBIEiE4DjlRDE1oK0RZMjVFISxNWjFgbgAoPxMuRRU3VBg9WBk4TBcuQBArPhUZKREqQBY0SxYtRRQySg06ThcjNw8fLQdYbSRUZCk2RQ0zVE1DgJMAHCcPHSYQIi8VHS0NKjoTLjocLzgsJjImGCQJEB0EGigNHzAKHigMMDoQITASPlAaWGkyQE0eO1AkSVtaAA8RDg0XBAwdAjA5Km16a5aopKi/xafDynyRl0lcTREbDBEWCxYcDis2Dy88HDxZQVdrPktbNExhRGySnQAWJg0iNh9JWk6ImIpQWko2LCFpW1Odnpa1xMKuw8V3goMfIx0YJREwQC1ESixbZFJRZTNLXitOZVUsOT8Ac4hQk6eSq7utsb+xmbGjbIpvY2lVdHtllKKSmaSdq7Wuhop4ZmU3X1w/YGI/UlQnPU8OPVUaJC4VBAAAAHOFPbenjcm0ndG8psnGtKrU0XvZ5VTR4ljR3TjO6DTK4lDO41XM3lqfoYOXhIaGYFRaI4iFYCsoICEiGABddSKemm3UvKzTvKjewarjwqvnzbfc0sHP2MvE2My82Mu21sqo083Az8S2sJ6Wh2lWXixRVUYwLyMyNiY73qgr4ePXgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/f994e/LesLoisirsPiscine2.webp 163w", "/static/4712e030725ca58ca34943dab647655d/e93cc/LesLoisirsPiscine2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/cf3f8/LesLoisirsPiscine2.png 163w", "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
              "alt": "Vrije tijd in het kasteel van Morey",
              "title": "Vrije tijd in het kasteel van Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Vrije tijd in het kasteel van Morey`}</strong></p>
    <h2 {...{
      "id": "wilt-u-ontspannen-en-tot-rust-komen-in-het-hart-van-lorraine"
    }}>{`Wilt u ontspannen en tot rust komen in het hart van Lorraine?`}</h2>
    <h3 {...{
      "id": "een-moment-van-welzijn-tussen-nancy-en-metz"
    }}>{`Een moment van welzijn tussen Nancy en Metz`}</h3>
    <p>{`Kom en vind comfort op het Château de Morey. Profiteer van onze privé SPA met 25 instelbare massagestralen in water van 38 graden met etherische oliën van Lorraines. Ontspan in een zen-sfeer in deze warme en ongewone kleine cocon.`}</p>
    <h3 {...{
      "id": "voor-een-ontspannende-massage-in-uw-kamer"
    }}>{`Voor een ontspannende massage in uw kamer:`}</h3>
    <p>{`Neem contact op met Celine op `}<a parentName="p" {...{
        "href": "tel:+33661347300",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`06 61 34 73 00`}</a></p>
    <h2 {...{
      "id": "de-activiteiten-van-het-kasteel-van-morey"
    }}>{`De activiteiten van het Kasteel van Morey`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ABgmECA0FS5GFic8EyxGEChEDSA4DRYnCDNPEkZgGkdeMU+Vt0if10Wk2UCr41twjW88OnA6IYErFHoxJwAYIw8UHwQLFwESHggfNA0XJwcUIAgeLxQZKws4UgtKWg1XiHpXruFsjrGdXXaga41iia5DcHRFXz4zV1QAjaiwcIiNUWRYHCYUBwwAFRwKFiAJHi8QL00/O11AOk4bXYJrd7rgjXeFnVlgqmVukYaVWn+CQk4fKDMSAKi+w5/AyZzCyoOorTtJSgQJBB4uHSk0IHqZpXOaqj5VQIKmsJO6y4/B1n6yyIK7zIq8z2yHjTpEIiw5GwB9Y06hnY6jnZWtraWwr6dYWEIwNRxeUz6BcVRRWUMvTBU+XTsoOh9BWEBMXz1CVS46Wz81SD4hJwsiLQsAVJCIXaajZaaqWqmxYa+2c7a5aKSbeqichqqTlKqMsK6EkIxqeXded3NWhodegoJdVl5AGBwRHSIKKzURAH7W5GLS4mDZ40Tc80Ha71XY6k7Y7GHW6IvY48be2fPVwqCJhXRjaGtaYWhkbWFZWkA2NhsbFSYrEDA3GgDlwavkx7Hhy7fjzLfky7Tmya/qyavtyrDwy7DtxqzkxKzTtZ/GqpaZg3VQSkUrMCYzNyojIhEyORc2Ox5vP8V+cif5BgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/f994e/LesLoisirsPiscine.webp 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/e93cc/LesLoisirsPiscine.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/cf3f8/LesLoisirsPiscine.png 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
              "alt": "Vrije tijd in Lotharingen",
              "title": "Vrije tijd in Lotharingen",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Vrije tijd in Lotharingen`}</strong></p>
    <h3 {...{
      "id": "een-ontspanningscomplex"
    }}>{`Een ontspanningscomplex`}</h3>
    <p>{`U zult kunnen genieten van een maximum aan wellnessruimte. Ons in 2001 gebouwde zwembad laat u toe om te zonnebaden, terwijl u omringd bent door een park met bomen en het geluid van de vogels. Een moment van ontspanning gegarandeerd. In de buurt van Nancy en Metz, verfris uzelf tijdens de zeer hete seizoenen van Lotharingen.`}</p>
    <h3 {...{
      "id": "geniet-van-meerdere-activiteiten-in-ons-huis-"
    }}>{`Geniet van meerdere activiteiten in ons huis :`}</h3>
    <ul>
      <li parentName="ul">{`💦 Spa`}</li>
      <li parentName="ul">{`💆 Massage`}</li>
      <li parentName="ul">{`🏊 Pool`}</li>
      <li parentName="ul">{`🐴 Paardrijden`}</li>
      <li parentName="ul">{`🏐 Boulodrome`}</li>
      <li parentName="ul">{`🎱 Biljart`}</li>
      <li parentName="ul">{`🎲 Speelkamer`}</li>
      <li parentName="ul">{`🌳 Een uitzonderlijk bosrijk park`}</li>
      <li parentName="ul">{`🚴 Fietsen tot uw beschikking`}</li>
      <li parentName="ul">{`🌐 Gratis Wifi`}</li>
      <li parentName="ul">{`📚 Bibliotheek`}</li>
      <li parentName="ul">{`🐾 Dieren`}</li>
    </ul>
    <p>{`Ons bucolisch park bestaat uit een grote tuin die wordt ondersteund door een muur van meer dan 5 meter hoog.
Met kastanjebomen, Libanese ceder, lindebomen, paarse beuken, eiken, esdoorn en een eendenvijver.
Een perfecte plek om te wandelen en te ontspannen.`}</p>
    <h2 {...{
      "id": "bezoek-lorraine"
    }}>{`Bezoek Lorraine`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHm2zhFsTLP/8QAGRABAQEAAwAAAAAAAAAAAAAAAAERAhIT/9oACAEBAAEFAvOpwdY1a1//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIT/9oACAEDAQE/AXCM5P/EABcRAAMBAAAAAAAAAAAAAAAAAAABAhP/2gAIAQIBAT8BVM0o/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAQADAQAAAAAAAAAAAAAAAQARMVGh/9oACAEBAAE/IeGGQ3CG/IRKwzl//9oADAMBAAIAAwAAABB07//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAwEBPxClu//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCNg//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQXFR/9oACAEBAAE/ECsUD2RByByszF/Eypqs6S5hLVfMgFUvSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/f994e/placestan.webp 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/bcd70/placestan.webp 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/5d8d7/placestan.webp 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/c4443/placestan.webp 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/5cc32/placestan.webp 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/448ad/placestan.webp 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/367e5/placestan.jpg 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/ab07c/placestan.jpg 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/cdb69/placestan.jpg 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/2616f/placestan.jpg 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg",
              "alt": "place stanislas",
              "title": "place stanislas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Place stanislas de Nancy`}</strong></p>
    <h3 {...{
      "id": "gelegen-tussen-nancy-en-metz-zou-de-ontdekking-van-deze-charmante-steden-u-wel-eens-kunnen-verbazen"
    }}>{`Gelegen tussen Nancy en Metz, zou de ontdekking van deze charmante steden u wel eens kunnen verbazen.`}</h3>
    <p>{`Nancy, op 20 minuten van het Château de Morey, staat bekend om zijn prachtige Place Stanislas en om zijn oude stad die u in de Middeleeuwen van Lotharingen zal onderdompelen. Er zijn veel musea te vinden in deze stad die vol geschiedenis zit.`}</p>
    <p>{`Metz, 30 minuten van het Château de Morey, is beroemd om zijn weelderige kathedraal en de keizerlijke Germaanse architectuur van de stad. Laat je leiden door de charmes van deze stad die meerdere tijdperken overstijgt.`}</p>
    <h3 {...{
      "id": "lorraine-is-de-thuisbasis-van-vele-bezienswaardigheden-en-monumenten-uit-alle-tijden-het-is-een-voorrecht-om-een-regio-te-hebben-die-zo-rijk-is-aan-geschiedenis"
    }}>{`Lorraine is de thuisbasis van vele bezienswaardigheden en monumenten, uit alle tijden, het is een voorrecht om een regio te hebben die zo rijk is aan geschiedenis.`}</h3>
    <p>{`Op 15 minuten van het Château de Morey, Pont à Mousson is een kleine stad vol charme, het heeft vele lokale restaurants, maar ook enkele uitzonderlijke monumenten. De bekendste is de Premonstratenzer abdij, een monument uit de 18e eeuw, het is een prachtig voorbeeld van de monastieke architectuur van Lotharingen.`}</p>
    <p>{`Een paar kilometer verderop vindt u :`}</p>
    <p>{`Verdun en de historische plaatsen van de Tweede Wereldoorlog
Het Madinameer en het Monument van de Butte de Montsec
Voor degenen die gepassioneerd zijn over Château, hebben we collega’s in de regio:`}</p>
    <ul>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.chateau-malbrouck.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Malbrouck`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Ch%C3%A2teau_de_Lun%C3%A9ville",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Luneville`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr/a-voir-a-faire/visites/sites-et-monuments/995000870-chateau-de-haroue-haroue",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Haroue`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      